.terms {

    font-size: small;
    color:rgb(54, 53, 53)

}



.topbar {

  background-color:#003366;
  height: 40px;
  padding: 10px;
  padding-right: 20px;
  position: fixed; 
  top: 0; 
  width: 100%; 

}
.topbarspace{
margin-top: 80px;
}
.footerbox {
    text-align:  center;
    height: 35px;
    background-color:#003366;
    vertical-align: middle;
    width: 100% ;
    

}

.footerbox h3 {
    color: #f2f2f2;
  text-align: center;
  line-height: 35px;
  

}


.form {
    padding: 10;
    background-color: beige;
}



